import { ReactNode } from "react";

export const Section: React.FC<{
  children: ReactNode;
}> = (props) => {
  return (
    <div className="snap-start relative flex flex-col items-center font mb-8">
      {props.children}
    </div>
  );
};
