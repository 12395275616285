import { Parallax } from "react-scroll-parallax";
import { Section } from "./Section";
import { TimelineEntry } from "./TimelineEntry";
import { TimelineSpacer } from "./TimelineSpacer";

export const App = () => {
  return (
    <>
      <div className="md:max-w-[30rem] md:m-auto md:my-4 md:shadow-lg md:shadow-black/50 md:rounded-lg bg-white">
        <Section>
          <div className="relative w-full">
            <img
              src="icons/e2.webp"
              alt=""
              className="absolute top-0 w-full opacity-70"
            />
          </div>

          <Parallax speed={-10} className="mt-10">
            <div>
              <div className="text-center pt-4 px-4 z-10">
                În atenția tuturor prietenilor și familiei
              </div>

              <div className="text-center text-7xl p-4 font-fancy z-10">
                Paula & Paul
              </div>
              <div className="text-center text-xl z-10 px-4">
                Se căsătoresc în sfârșit!
              </div>
            </div>
          </Parallax>
          <img src="icons/us.webp" alt="" className="z-10 mt-16" />
          <div className="text-center p-5 text-lg leading-relaxed text-balance">
            Așadar, împreună cu nașii{" "}
            <div className="font-bold my-4 text-xl">Antonia și Emil Petrea</div>{" "}
            avem plăcerea de a vă invita să fiți, nu doar martori la eveniment,
            dar și participanți la petrecerea ce urmează
          </div>
        </Section>

        <Section>
          <Parallax speed={-4} className="absolute w-full">
            <img
              src="icons/e4.webp"
              className="absolute left-0 top-0 h-80 opacity-40"
              alt=""
            />
          </Parallax>
          <Parallax speed={-3} className="absolute w-full">
            <img
              src="icons/e5.webp"
              className="absolute right-0 top-52 h-80 opacity-40"
              alt=""
            />
          </Parallax>
          <div className="text-center">Vă așteptăm pe data de</div>
          <div className="text-center font-fancy text-5xl mb-8">
            16 noiembrie 2024
          </div>

          <div className="flex w-full justify-center">
            <div className="basis-1/2 z-10">
              <TimelineEntry
                img="icons/3.svg"
                title="Cununia Civilă"
                location="Parcul Central"
                time="12:00"
                side="left"
                locationLink="https://maps.app.goo.gl/XHbYFPBVtxcxmZi46"
              />
              <TimelineSpacer />
              <TimelineEntry
                img="icons/4.svg"
                title="Petrecere"
                location="Sala Marion"
                time={"16:00"}
                side="left"
                locationLink="https://maps.app.goo.gl/jK2fkcJSgo5ZkiqC6"
              />
            </div>
            <div className="bg-stone-300 min-h-full w-1 rounded-lg">&nbsp;</div>
            <div className="basis-1/2 z-10">
              <TimelineSpacer />
              <TimelineEntry
                img="icons/5.svg"
                title="Cununia Religioasă"
                location={`Biserica Ortodoxă a "Tuturor Sfinților"`}
                time="13:00"
                side="right"
                locationLink="https://maps.app.goo.gl/FMXPpAxGXFo4pMNE9"
              />
            </div>
          </div>
        </Section>
        {/* <RsvpSection /> */}
        <img src="icons/e3.webp" className="opacity-70" alt="" />
      </div>
    </>
  );
};
