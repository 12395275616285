import classNames from "classnames";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";

export const TimelineEntry: React.FC<{
  side: "left" | "right";
  img: string;
  title: string;
  location: string;
  time: React.ReactElement | string;
  locationLink: string;
}> = (props) => {
  return (
    <div
      className={classNames("flex flex-col", {
        "text-right items-end pr-4": props.side === "left",
        "text-left items-start pl-4": props.side === "right",
      })}
    >
      <img src={props.img} className="w-16 -mb-4" alt="" />
      <div className="font-fancy text-3xl mb-2">{props.title}</div>
      <div
        className={classNames("flex items-start text mb-1", {
          "flex-row-reverse": props.side === "left",
        })}
      >
        <IoLocationOutline
          className={classNames("text-stone-400 size-5 min-w-5", {
            "ml-1 -mr-1": props.side === "left",
            "mr-1 -ml-1": props.side === "right",
          })}
        />
        <a
          className="underline decoration-dashed underline-offset-4 decoration-primary hover:decoration-primary-darker"
          href={props.locationLink}
          target="_blank"
          rel="noreferrer"
        >
          {props.location}
        </a>
      </div>
      <div
        className={classNames("flex items-start text", {
          "flex-row-reverse": props.side === "left",
        })}
      >
        <IoTimeOutline
          className={classNames("text-stone-400 size-5", {
            "ml-1 -mr-1": props.side === "left",
            "mr-1 -ml-1": props.side === "right",
          })}
        />
        {props.time}
      </div>
    </div>
  );
};
